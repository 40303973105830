.sign-req-main {

    .sign-req-inner {
        display: flex;
        justify-content: center;

        .stepper-container {
            width: 50%;

            @media screen and (max-width: 1150px) {
                width: 70%;
            }

            @media screen and (max-width: 950px) {
                width: 100%;
            }
        }
    }
}