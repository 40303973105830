@import "../../../scss/variable.scss";

.footer-container {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 200px;

    @media screen and (min-width: 769px) and (max-width: 1024px){
        margin-bottom: 600px;
    }
}

.footer-title-list-container {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
}

.footer-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-list-items {
    font-size: 25px;
    color: $color-home-desc-dark;
}

