@import '../../scss/variable.scss';

.deferred-signature-main {

    .file-wrapper {
        display: flex;
        justify-content: center;
    }

    .file-inner-wrapper {
        width: 50%;

        @media screen and (max-width: 1150px) {
            width: 70%;
        }

        @media screen and (max-width: 950px) {
            width: 100%;
        }
    }

    .title {
        font-size: 25px;
        color: $color-qa-title;
        margin-top: 30px;
    }

    .sign-btns-container {
        display: flex;
        justify-content: center;
        margin-top: 70px;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }

        .signature-btns {
            height: 62px;
            width: 251px; 
            
        }

        .signature-margin-right {
            margin-right: 14px;

            @media screen and (max-width: 600px) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
}