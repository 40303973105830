@import '../../scss/variable.scss';

.sidebar {
    display: flex;
    flex-direction: column;
    z-index: $fixed-sidebar-z-index;
    background-color: $color-green;
    color: white;
    width: $fixed-sidebar-width;
    min-width: $fixed-sidebar-width;
    height: 100%;
    position: fixed;
    overflow: auto;

    .sidebar-menu-container {
        display: flex;
        flex-direction: column;
        height: $sidebar-menu-icon-container-height;

        &:hover {
            // background-color: white;
            // color: $color-green;
        }
    }

    .sidebar-menu {
        margin: auto;
        font-size: 58px;

        &:hover {
            font-size: 60px;
            cursor: pointer;
        }
    }

    .sidebar-items-container {
        margin-top: 100px;
    }

    .sidebar-items {
        display: flex;
        flex-direction: column;
        height: 65px;
        //height: $sidebar-items-height;

        &:hover {
            // background-color: white;
            // color: $color-green;
        }
    }

    .sidebar-icons {
        font-size: 58px;
        margin: auto;

        &:hover {
            cursor: pointer;
        }
    }

    .active {
        background-color: white;
        color: $color-green;
    }
}

@media screen and (max-width: 1800px) {
    .sidebar {
        .sidebar-items-container {
            margin-top: 50px;
        }

        .sidebar-items {
            height: 70px;
        }

        .sidebar-icons {
            width: 40px;
            height: 40px;
        }
    }
}

@media screen and (max-width: 1450px) {
    .sidebar {
        .sidebar-items-container {
            margin-top: 40px;
        }

        .sidebar-items {
            height: 54px;
        }

        .sidebar-icons {
            width: 34px;
            height: 34px;
        }
    }
}

@media screen and (max-width: 768px) {
    .sidebar {
        display: none;
        width: $mobile-fixed-sidebar-width;
        min-width: $mobile-fixed-sidebar-width;

        .sidebar-items {
            height: $mobile-sidebar-items-height;
        }
    }
}
