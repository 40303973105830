:root {
  --signin-background: #F6F9FA;
  --signin-title: #323232;
  --sign-description: #707070;
  --sign-up-color: #4DC5E1;
  --color-home-title-dark : #858589;
  --color-home-title-light : #82D4C5;
  --color-home-desc-dark: #5D5C5E;
  --color-qa-title: #484848;
  --message-icon-z-index: 100;
}

.signin-bg-clr {
  background-color: var(--signin-background);
}

.signin {
  width: 100%;
  height: 100vh;
  /* background: var(--signin-background) 0% 0% no-repeat padding-box; */
  background-color: var(--signin-background);
  opacity: 1;
}

.just-signin-logo {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 16vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signin-logo {
  padding-top: 68px;
  padding-bottom: 22px;
}

.just-signin-logo-size {
  /* margin: auto; */
  width: 100%;
  height: 597px;
}

.signin-logo-size {
  /* margin: auto; */
  width: 100%;
  /*height: 597px;*/
}

.just-signin-form-container {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #A8DEF0;
  border-radius: 27px;
  opacity: 1;
  /* margin-bottom: 200px; */
}

.signin-form-container {
  display: flex;
  flex-direction: column;
  padding: 108px 64px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #A8DEF0;
  border-radius: 27px;
  opacity: 1;
  /* margin-bottom: 200px; */
}

.upload-file-container {
  display: flex;
  flex-direction: column;
  padding: 26px 64px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #A8DEF0;
  border-radius: 27px;
  opacity: 1;
}

.upload-file-inner-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.upload-file-inner-wrapper-container  {
  max-width: 800px;
  width: 100%;
}

.just-signin-title {
  /* font: normal normal 600 30px/37px Montserrat;  */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 10vh;
  font-size: 50px;
  letter-spacing: 0px;
  /*color: var(--signin-title);*/
  color: #4C4C6D;
  opacity: 1;
}

.signin-title {
  /* font: normal normal 600 30px/37px Montserrat;  */
  font-size: 30px;
  letter-spacing: 0px;
  color: var(--signin-title);
  opacity: 1;
}

.just-signin-description {
  /* font: normal normal normal 20px/30px Montserrat; */
  height: 11vh;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 0px;
  color: var(--sign-description);
  opacity: 1;
  /*padding-bottom: 60px;*/
}

.signin-description {
  /* font: normal normal normal 20px/30px Montserrat; */
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 0px;
  color: var(--sign-description);
  opacity: 1;
  padding-bottom: 60px;
}

.just-signin-forgot-password {
  cursor: pointer;
  float: right;
  margin-bottom: 20px;
  height: 10vh;
  /* font: normal normal normal 20px/24px Montserrat; */
  font-size: 20px;
  letter-spacing: 0px;
  color: var(--signin-title);
  opacity: 1;
}

.forgot-password {
  cursor: pointer;
  float: right;
  margin-bottom: 81px;
  /* font: normal normal normal 20px/24px Montserrat; */
  font-size: 20px;
  letter-spacing: 0px;
  color: var(--signin-title);
  opacity: 1;
}

.just-signin-already-account {
  height: 6vh;
  margin-top: 18px;
  display: block;
  text-align: center;
  /* font: normal normal normal 20px/24px Montserrat; */
  font-size: 20px;
  letter-spacing: 0px;
  color: #050505;
}

.already-account {
  margin-top: 18px;
  display: block;
  text-align: center;
  /* font: normal normal normal 20px/24px Montserrat; */
  font-size: 20px;
  letter-spacing: 0px;
  color: #050505;
}

.already-account_signup {
  font-size: 20px;
  /* font: normal normal normal 20px/24px Montserrat; */
  letter-spacing: 0px;
  color: var(--sign-up-color)
}

.forgot-btns {
  /*margin-top: 130px!important;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.fifty-percent-width {
  width: 48%;
  max-width: 48%;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-color-title-dark {
  color: var(--color-home-title-dark);
}

.home-color-title-light {
  color: var(--color-home-title-light);
}

.home-color-desc-dark {
  color: var(--color-home-desc-dark);
}

.message-icon_container {
  bottom: 0;
  right: 0;
  z-index: var(--message-icon-z-index);
  position: fixed;
}

.message-icon {
  margin-right: 60px;
  margin-bottom: 20px;
}

.dotted-background {
  width: 100%;
  height: 100%;
}

.mt-0 {
  margin-top: 0;
}

.table-title {
  font-size: 26px;
  font-weight: bold;
}

.job-stats-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.dashboard-title-desc {
  color: var(--color-home-title-dark);
  font-size: 16px;
}

.qa-title {
  font-size: 25px;
  margin-bottom: 23px;
  color: var(--color-qa-title);
}

.new-size-signin-forgt-btns {
  height: 72px;
  border-radius: 36px!important;
}

.new-signin-logo {
  height: 350px;
}

.latest-trimmed-logo {
  height: 60px;
}

/*.new-form-grid-padding {*/
/*  padding: 12px 50px!important;*/
/*}*/

/*.MuiInput-underline:before {*/
/*  border-bottom: 1px solid #707070!important;*/
/*}*/

/*.MuiInput-underline:hover:not(.Mui-disabled):before {*/
/*  border-bottom: 2px solid #707070!important;*/
/*}*/

/*.MuiInput-underline:after {*/
/*  border-bottom: 2px solid #707070!important;*/
/*}*/

/*modal css*/
.header-title {
  padding: 20px;
  font-size: 20px;
  background-color: #b8dfd8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .header-content {

} */

.modal-container {
  padding: 8px 0;
  min-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between
}


@media screen and (max-width: 1450px) {
  .new-size-signin-forgt-btns {
    height: 54px;
  }

  .new-form-grid-padding {
    padding: 12px 50px!important;
  }
}

/*@media screen and (max-width: 1024px) {*/
/*  .new-size-signin-forgt-btns {*/
/*    height: 54px;*/
/*  }*/
/*}*/



@media screen and (max-width: 850px) {
  .just-signin-form-container {
    padding: 40px 25px;
  }

  .signin-form-container {
    padding: 40px 25px;
  }

  .upload-file-container {
    padding: 40px 30px;
  }

  .forgot-btns {
    flex-direction: column;
    /*margin-top: 30px!important;*/
  }

  .fifty-percent-width {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  /*.latest-trimmed-logo {*/
  /*  height: 60px;*/
  /*}*/

  .new-form-grid-padding {
    padding: 12px 20px!important;
  }

  .new-signin-logo {
    height: 180px;
  }

  .just-signin-logo {
    flex-direction: row;
  }

  .just-signin-description {
    font-size: 16px;
  }

  .new-size-signin-forgt-btns {
    height: 50px;
  }
}

@media screen and (max-width: 600px) {
  .latest-trimmed-logo {
    height: 40px;
  }

  .new-size-signin-forgt-btns {
    height: 46px;
  }

  .signin-logo {
    text-align: center;
  }

  .signin-logo-size {
    width: 100%;
    height: 300px;
  }

  .just-signin-form-container {
    padding: 40px 30px;
  }

  .signin-form-container {
    padding: 40px 30px;
    /* margin-bottom: 50px; */
  }

  .upload-file-container {
    padding: 40px 30px;
  }

  /* .fifty-percent-width {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
  } */
}

.file-badge {
  height: 29px;
  width: 81px;
  margin-right: 7px;
  background-color: #82D4C533;
  padding: 5px 15px 4px 16px;
  color: #707070
}

.delete-badge {
  margin-left: 16px;
   margin-right: 13px;
}

.add-badge {
  border-radius: 14px;
  height: 27px;
  width: 88px;
  margin-right: 7px;
  background-color: #82D4C5;
  padding: 5px 27px 4px 27px;
  color: #ffffff;
}

.jobs-br-right {
  border-top-right-radius: 23px!important;
  border-bottom-right-radius: 23px!important;

  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.jobs-br-left {
  border-top-left-radius: 23px!important;
  border-bottom-left-radius: 23px!important;

  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

/*image hover class*/
.image-hover:hover {
    cursor: pointer;
}

.image-hover_not_allowed:hover {
  cursor: not-allowed;
}

.certiff-header {
  top: 0;
  left: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  position: fixed;
  min-height: 50px;
  background-color: white;
  box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0 rgba(0, 0, 0, 0.12);
}

.certiff-container {
  max-width: 900px!important;
}

.certiff-hero_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50px;
  min-height: 350px;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,124,186,1) 0%, rgba(0,212,255,1) 100%);
}

.certiff-hero_banner h1 {
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certif-logo {
  max-height: 50px;
}

.current-user_logo-container {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.current-user_logo {
  max-height: 150px;
}

.certif-footer {
  margin-top: 100px;
  padding-bottom: 150px;
  padding-top: 20px;
  border-top: 1px solid #707070;
  display: flex;
  justify-content: center;
}

.link-certiff-container {
  display: flex;
  align-items: center;
}

.link {
  font-size: 15px;
  color: #777777;
  transition: all 0.9s ease;
}

.link:hover {
  color: #333333;
  cursor: pointer;
  /* text-decoration: underline; */
}

.link-pad-left {
  padding-left: 20px;
}

@media screen and (max-width: 600px) {
  .current-user_logo {
    max-height: 80px;
  }

  .certif-footer {
    margin-top: 50px;
    padding-bottom: 15px;
  }
}