.channel-management {

    .title {
        font-size: 25px;
        color: #484848;
        margin-bottom: 4px;
    }

    .channel-btns-container {
        position: relative;
        padding-top: 10px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;

        //copied start
        .input-custom-generic {
            font-size: 18px;
            color: black;
            padding: 6px 8px 6px 12px;
            height: 46px;
            border: 1px solid #707070;

            &:hover {
                cursor: pointer;
            }

            &:focus {
                outline: none;
                box-shadow: 0px 0px 1px rgba(0,100,255,1),
                0px 0px 2px rgba(0,100,255,1),
                0px 0px 3px rgba(0,100,255,1)
            }
        }

        .input-left-bordered {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }

        .input-right-bordered {
            background-color: white;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
        }

        .input-date-width {
            max-width: 100%;

            @media screen and (max-width: 767px){
                width: 100%!important;
            }
        }
        //copied end

        .btn-date-picker-container {
            width: auto;

            @media screen and (max-width: 767px) {
                width: 100%;
                // margin-bottom: 10px;
            }
        }

        .date-container-tesoz {
            position: absolute;
            top: 40px;

            @media screen and (max-width: 767px) {
                top: 100px;
                left: 100px;
            }

            @media screen and (max-width: 400px) {
                top: 100px;
                left: 0;
            }

        }

        .by-name {
            font-size: 20px;
            height: 46px;
            width: 25%;
            margin-bottom: 6px;
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        .by-expiration-date {
            position: relative;
            font-size: 20px;
            height: 46px;
            width: 100%;
            margin-bottom: 6px;
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                width: 100%!important;
                max-width: 100%!important;
                margin-bottom: 10px;
            }
        }

        .by-create {
            height: 46px;
            width: 160px;
            margin-bottom: 6px;
            font-size: 20px;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}
