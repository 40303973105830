.manage-users-edit {

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin-bottom: 430px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 200px;
  }

  .title {
    font-size: 25px;
    color: #484848;
    margin-bottom: 20px;
  }

  .edit-account-form {

    .edit-acc-label {
      padding: 10px;
      color: #484848;
      font-size: 20px;

      @media screen and (max-width: 600px) {
        padding-left: 0;
        font-size: 18px;
      }
    }

    .add-checkbox-field {
      height: 40px;
      width: 40px;
      background-color: white;

      @media screen and (max-width: 600px) {
        height: 47px;
      }
    }

    .select-field {
      height: 54px;
      background-color: white;

      @media screen and (max-width: 600px) {
        height: 47px;
      }
    }

    .edit-acc-text-field {
      width: 100%;
      color: #858589;
      font-size: 20px;
      padding: 11px 0 10px 21px;
      border: 1px solid #707070;

      @media screen and (max-width: 600px) {
        font-size: 16px;
        padding: 10px 2px 10px 10px;
      }
    }

    .edit-margin-btm {
      margin-bottom: 5px;
    }
  }

  .sign-btns-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }

    .signature-btns {
      height: 62px;
      width: 251px;

      @media screen and (max-width: 600px) {
        height: 46px;
        width: 100%;
        font-size: 18px!important;
      }

    }

    .signature-margin-right {
      margin-right: 14px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
