@import '../../scss/variable.scss';

.layout {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    max-width: 100%;

    .header-and-content-container {
        width: auto;
    }

    .content-container {
        padding: 0 20px 20px 20px;
        position: absolute;
        top: 220px;
        // top: $layout-container-top;
        right: 0;
        left: $layout-container-left;
        // width: $layout-width;
        max-width: $layout-width;
    }

    .bread-crumb {
        z-index: 50;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;
        font-size: 20px;
        padding: 0 100px;
        color: black;
        left: 127px;
        top: 145px;
        position: fixed;
        height: 56px;
        background: transparent linear-gradient(109deg, #B8DFD8 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 7px #00000029;
        border: 1px solid #0A49BE;
        border-left: none;
        opacity: 1;

        @media screen and (min-width: 1451px) {
            top: 156px;
            height: 70px;
        }
    }
}

@media screen and (max-width: 768px) {
    .layout {
        .content-container {
            top: 160px;
            left: 0;
            padding: 0;
            width: 100%;
            max-width: 100%!important;
        }

        .bread-crumb {
            display: none;
        }
    }
}
