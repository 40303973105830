.success-file_c {
  width: 100%;
  height: 100%;
  border: 1px solid #707070;

  .success-file_header {
    display: flex;
    flex-direction: row;
    padding: 18px;
    background-color: #40BAD5;

    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin: 3px 3px 0 3px;

    .cancel-icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }

  .arrow-down_c {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .down-triangle {
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #40BAD5;
    }
  }

  .success-file_sub {
    padding: 21px 19px;

    .sub_child {
      padding-bottom: 6px;

      .text-bold {
        padding-left: 4px;
        font-weight: bold;
      }

    }
  }

  .success-file_detail {
    font-size: 14px;
    color: #858589;
    line-height: 1.3rem;
    letter-spacing: 0.5px;

    .more-less_container {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      label {
        display: flex;
        color: black;
        font-size: 16px;
      }

    }

    .further-detail_container {
      .each_detail {
        padding-bottom: 6px;
        //line-break: anywhere;

        label {
          font-weight: bold;
          text-decoration: underline;
          // color: lightblue;
        }

        .text-bold {
          padding-left: 4px;
          font-weight: bold;
        }

        .stats {
          text-decoration: underline;

          &:hover {
            cursor: pointer!important;
        }

        }

        .text-over-flow {
          overflow-wrap: anywhere;
        }
      }
    }
  }

}
