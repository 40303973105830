.document-list-content {

    .btns-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 43px;
        flex-wrap: wrap;
        flex-grow: 1;

        .btns-font-size {
            font-size: 20px;
            height: 46px;
        }


        .left {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex-grow: 1;

            .input-custom-generic {
                font-size: 18px;
                color: black;
                padding: 6px 8px 6px 12px;
                height: 46px;
                border: 1px solid #707070;

                &:hover {
                    cursor: pointer;
                }

                &:focus {
                    outline: none;
                    box-shadow: 0px 0px 1px rgba(0,100,255,1),
                    0px 0px 2px rgba(0,100,255,1),
                    0px 0px 3px rgba(0,100,255,1)
                }
            }

            .input-left-bordered {
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
            }

            .input-right-bordered {
                background-color: white;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
            }

            .input-date-width {
                max-width: 100%;

                @media screen and (max-width: 767px){
                    width: 100%!important;
                }
            }


            .by-related-job {
                // width: 180px;
                min-width: 170px;
                max-width: 180px;
                margin-bottom: 6px;
                margin-right: 10px;

                @media screen and (max-width: 767px) {
                    min-width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                    margin-right: 0;
                }
            }

            .by-doc-name {
                // width: 240px;
                min-width: 220px;
                max-width: 240px;
                margin-bottom: 6px;
                margin-right: 10px;

                @media screen and (max-width: 767px) {
                    min-width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                    margin-right: 0;
                }
            }

            .by-creation-date-range {
                // width: 285px;
                min-width: 250px;
                max-width: 285px;
                margin-bottom: 6px;
                margin-right: 10px;

                @media screen and (max-width: 767px) {
                    //width: 100%;
                    min-width: 100%;
                    //max-width: 100%;
                    margin-bottom: 10px;
                    margin-right: 0;
                }
            }

            .by-search {
                // width: 160px;
                min-width: 140px;
                //max-width: 160px;
                margin-bottom: 6px;

                @media screen and (max-width: 767px) {
                    min-width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                }
            }
        }

        .right {
            .by-export {
                width: 160px;
                margin-bottom: 6px;
                margin-left: 20px;

                @media screen and (max-width: 1400px) {
                    margin-left: 6px;
                }

                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 10px;
                    margin-left: 0;
                }
            }

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
}
