.languageAndMenu {
    display: flex;
    
    .overlay {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0);
        z-index: 2;
        cursor: pointer;
    }

    .menu-display-none {
        display: none;
     }

    .language {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-right: 10px;

        &:hover {
            cursor: pointer;
        }
    }

    .line {
        border: 1px solid #B8DFD8;
    }

    .menu-item {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 16px;

        &:hover {
            cursor: pointer;
        }
    }

    .down-icon {
        margin-left: 4px;
    }

    .profile-icon {
        margin-right: 4px;
    }
}