@import "../../scss/variable.scss";

.stats-container {
    margin-right: 36px;
    width: 210px;
    min-width: 210px;
    height: 178px;
    border: 1px solid $color-table-border;
    text-align: center;
    margin-bottom: 20px;

    .flex-clm {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title {
        font-size: 20px;
        height: 58px;
        // background-color: $color-bg-table-header;
        background-color: #40BAD5;
        // color: $color-table-text;
        color: white;
        text-align: center;
        border: 3px solid white;
    }

    .description {
        height: 118px;
        color: black;
        font-size: 26px;
    }
}

.qa-container {
    width: 100%;
    min-width: 100%;
    height: auto;
    border: 1px solid $color-table-border;
    margin-bottom: 14px;

    .flex-clm {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title {
        padding: 13px 46px;
        font-size: 25px;
        // background-color: $color-bg-table-header;
        color: $color-qa-title;
        background-color: #40BAD5;
        color: white;
        border: 3px solid white;
    }

    .description {
        padding: 13px 46px;
        height: auto;
        color: $color-table-border;
        font-size: 20px;
    }
}

@media screen and (max-width: 587px){
    .stats-container {
        width: 100%;
        margin-right: 0;
    }
}