.deferred-docs-pending {
    
    .btns-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 43px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1;

        .btns-font-size {
            font-size: 20px;
            height: 46px;
        }
        

        .left {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            flex-wrap: wrap;
            flex-grow: 1;
            
    
            .by-related-job { 
                width: 180px;
                margin-bottom: 6px;
                margin-right: 10px;
    
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
    
            .by-doc-name { 
                width: 240px;
                margin-bottom: 6px;
                margin-right: 10px;
    
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
    
            .by-creation-date-range { 
                width: 285px;
                margin-bottom: 6px;
                margin-right: 10px;
    
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
    
            .by-search { 
                width: 160px;
                margin-bottom: 6px;
                
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    
        .right {
            .by-export {
                width: 160px;
                margin-bottom: 6px;
                margin-left: 20px;
    
                @media screen and (max-width: 1400px) {
                    margin-left: 6px;
                }
    
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 10px;
                    margin-left: 0;
                }
            }
    
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
}