@import "../../../scss/variable.scss";

.contact-us-container {
    margin-top: 20px;
    width: 100%;
    background-color: #E8F6EF;
    // overflow-x: hidden;
    // overflow-y: hidden;
    // position: ab;
    // right: 116px;
    // width: calc(100% + 234px);
    

    // @media screen and (max-width: 1000px){
    //     overflow-y: hidden;
    //     overflow-x: hidden;
    //     position: relative;
    //     right: 0;
    //     width: 100%;
        
    // }


.contact-us-title {
    display: block;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    // margin-top: 144px;
    padding-top: 144px;
    margin-bottom: 100px;
}

.contact-us-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px){
        padding: 0 10px;
     }
 }
}

.contact-us-text-fields {
    padding: 27px 20px 26px 40px;
    font-size: 25px;
    border: 1px solid $color-contct-us-input-field;
    border-radius: 9px;
    max-width: 939px;
    min-width: 650px;

    @media screen and (max-width: 1000px){
        max-width: calc(100% - 100px);
        min-width: calc(100% - 100px);
    }

    @media screen and (max-width: 767px){
        max-width: 100%;
        min-width: 100%;
        padding: 10px 10px 10px 14px;
        font-size: 18px;
    }
}

.mgn-btm {
    margin-bottom: 22px;
}

.contact-input {
    
}

.contact-text-area {
    min-height: 200px;
}

.contact-us-btn {
    font-size: 24px!important;
}

.btn-margin {
    margin-top: 90px;
    margin-bottom: 178px;
   

    @media screen and (max-width: 500px){
        margin-top: 50px;
        margin-bottom: 100px;
        max-height: 100px!important;
        // padding: 0 20px;
        padding: 0;
    }
}

.btn-max-height {
    @media screen and (max-width: 767px){
        max-height: 70px;
        width: 100%;
        font-size: 18px!important;
    }
}