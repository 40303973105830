@import "../../scss/variable.scss";

.about-us-table {
    display: flex;
    flex-direction: column;
    margin-bottom: 37px;
    border: 1px solid $color-table-border;

    .about-us-table_header {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-size: 25px;
        // color: $color-qa-title;
        // background-color: $color-bg-table-header;
        color: white;
        background-color: #40BAD5;
        margin: 3px;

        @media screen and (max-width: 600px) {
            font-size: 15px;
        }

        .header-left {
            padding: 19px 0;
            width: 30%;
        }

        .header-right {
            padding: 19px 0;
            width: 70%;
        }
    }

    .about-us-table_row {
        display: flex;
        flex-direction: row;
        font-size: 15px;
        word-wrap: break-word;
        white-space: pre-line;
        overflow-wrap: break-word;
        color: $color-qa-title;
        border-bottom: 1px solid $color-table-border;

        @media screen and (max-width: 600px) {
            font-size: 13px;
        }

        .about-us-table-row_left {
            max-width: 30%;
            width: 30%;
            //min-width: 30%;
            text-align: center;
            padding: 13px 0 15px 0;
            border-right: 1px solid $color-table-border;

            @media screen and (max-width: 600px) {
                min-width: 30%;
                vertical-align: middle;
                text-align: center;
            }
        }

        .about-us-table-row_right {
            max-width: 70%;
            width: 70%;
            // min-width: 70%;
            word-wrap: break-word;
            white-space: pre-line;
            overflow-wrap: break-word;
            padding: 13px 10px 15px 20px;

            @media screen and (max-width: 600px) {
                padding: 10px 5px 15px 20px;
            }
        }
    }

    .about-us-table_row:last-child {
        border-bottom: none;
    }

}