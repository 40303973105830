.check-box-input {
  width: 27px;
  height: 27px;

  &:hover{
    cursor: pointer;
  }
}

[type="checkbox"]:checked {
  background: #20A204;
}



input[type="checkbox"]:checked {
  background-color: #20A204;
}

input[type="checkbox"]::selection {
  background-color: #20A204;
}

.check-box-input[type="checkbox"]:checked {
  background-color: #20A204;
}

.check-box-input[type="checkbox"]::selection {
  background-color: #20A204;
}



