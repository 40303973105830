@import "../../scss/variable.scss";

.file-not-found {
    width: 100%;
    height: 100%;
    border: 1px solid $color-table-border;

    .file-title {
        display: flex;
        flex-direction: row;
        padding: 18px;
        // background-color: $color-bg-table-header;
        background-color: #40BAD5;
        @media screen and (max-width: 585px) {
            flex-direction: column;
        }

        .cancel-icon {
            width: 20px;
            height: 20px;
            margin-right: 16px;
        }

    }

    .file-title-main {
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 20px; 
        font-weight: bold;
        // color: $color-checkfile-error-title;
        color: white;
        margin: 3px 3px 0 3px;
    }

    .file-title-sub {
        font-size: 16px; 
        // color: $color-home-title-dark;
        color: white;
        margin: 3px 3px 0 3px;
    }

    .arrow-down-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .down-triangle {
            width: 0; 
            height: 0; 
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        
            // border-top: 20px solid $color-bg-table-header;
            border-top: 20px solid #40BAD5;
        }
    }

    .file-detail {
        font-size: 14px;
        color: $color-home-title-dark;
        line-height: 1.3rem;
        letter-spacing: 0.5px;

        @media screen and (max-width: 600px) {
            padding: 15px 10px;
        }

    }

    .file-detail-main {
        text-align: center;
        padding: 20px 36px 40px 36px;
    }


    .file-detail-sub {
        padding: 21px 19px;
    }
    
}