@import '../../scss/variable.scss';

.sidebarOpen {
    z-index: $toggle-sidebar-z-index;
    display: block;
    width: $toggle-sidebar-width;
    height: 100%;
    padding: 24px 0 0 27px;
    position: fixed;
    cursor: pointer;
    color: white;
    background-color: $color-green;

    .close-icon_container {
        padding-bottom: 100px;
    }

    .close-icon {
        color: white;
        margin-bottom: 20px;
        font-size: 30px;
    }

    .items-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 65%;

        @media screen and (max-width: 1024px) {
            height: 50%;
        }

        @media screen and (max-width: 767px) {
            height: 75%;
        }
    }

    .logout-items-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 30%;

        //@media screen and (max-width: 1024px) {
        //    height: 50%;
        //}
        //
        //@media screen and (max-width: 767px) {
        //    height: 75%;
        //}
    }

    .sidebar-item {
        display: flex;

        // &:hover {
        //     border-bottom: 1px solid white;
        // }
    }

    .sidebar-icons {
        // font-size: 58px;
        // margin: auto;
        width: 30px;
        height: 30px;

        &:hover {
            cursor: pointer;
        }
    }

    .icon-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 35px;
        font-size: 20px;
    }
}

@media screen and (max-width: 600px) {
    .sidebarOpen {
        width: 100vw;
    }
}

.sidebarClose {
    display: none;
}
