@import "../../../scss//variable.scss";

.lets-connect-mainContainer {
    margin-top: 300px; 
    width: 100%;

    @media screen and (max-width: 500px){
        margin-top: 100px; 
    }
}

.title-icon-wrapper {
    border-bottom: 3px solid $color-table-border;
}

.connect-title {
    font-size: 50px;
    color: $color-table-text;

    @media screen and (max-width: 450px) {
        text-align: center;
    }

}

.icons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media screen and (max-width: 450px) {
        // flex-direction: column;
        // align-items: center;
        flex-wrap: wrap;
        flex-grow: 1;
    }

    .icon-container {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $color-social-media-icons;

        @media screen and (max-width: 450px) {
            margin-bottom: 10px;
        }
    }
}

.city {
    margin: 78px 0;

    @media screen and (max-width: 450px) {
        margin: 20px 0;
    }
}

.email {
    margin: 0 0 78px 0;

    @media screen and (max-width: 450px) {
        margin: 0 0 20px 0;
    }
}

.city, .ph-no, .email {
    color: $color-home-desc-dark;
    font-size: 25px;
}

