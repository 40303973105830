//colors
$color-green: #b8dfd8;
$color-green-hover: darken($color-green, 10%);

$color-red: red;
$color-red-hover: darken($color-red, 10%);

$color-home-title-dark: #858589;
$color-home-title-light: #82d4c5;
$color-home-desc-dark: #5d5c5e;
$color-social-media-icons: #b6b6b7;
$color-contanct-us-form: #e8f6ef;
$color-contct-us-input-field: #035aa6;
$color-about-us-title: #290c41;

$color-bg-table-header: #ffe194;
$color-table-text: #4c4c6d;
$color-table-border: #707070;

$color-qa-title: #484848;
$color-checkfile-error-title: #e60303;

//sizes
$header-height: 106px;
$header-width: calc(100% - 127px);
$mobile-header-width: calc(100% - 80px);
$fixed-sidebar-width: 127px;
$mobile-fixed-sidebar-width: 80px;
$toggle-sidebar-width: 521px;
$sidebar-items-height: 80px;
$mobile-sidebar-items-height: 65px;
$mid-size-sidebar-items-height: 54px;
$sidebar-menu-icon-container-height: 106px;

//layout sizes
$layout-container-top: 150px;
$layout-container-left: 127px;
$mobile-layout-container-left: 80px;
// $layout-width: calc(100% - 170px);
$layout-width: calc(100% - 128px);
$mobile-layout-width: calc(100% - 80px);

//home layout sizes
$home-layout-width: calc(100% - 128px);

//z-indexes
$header-z-index: 50;
$fixed-sidebar-z-index: 90;
$toggle-sidebar-z-index: 100;
$message-icon-container: 100;
