label {
  cursor: pointer;

  .upload-text_span {
    color: skyblue;
    text-decoration: underline;

  }

  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
