.contact-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  //height: 200px;
  //font-size: 24px;

  .us-link {
    text-align: center;
    color: #7B87F6;
    //border-bottom: 1px solid #707070;
    margin-top: 150px;
    margin-bottom: 50px;
    line-break: anywhere;

    //.a.menu:hover span {
    //  border-bottom: 2px solid black;
    //}

  }
}
