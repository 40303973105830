@import '../../scss/variable.scss';

.check-files-container {
  padding-top: 50px;

  .for-understanding {
    font-size: 14px;
    color: $color-home-title-dark;
    text-align: center;
    padding: 19px 10px 25px 10px;  
  }

  .fifty-percent-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }
}
