.jobs-content {

    .btns-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1;
        min-height: 120px;
        max-height: 350px;

    }

    .btns-font-size {
        font-size: 20px;
        height: 46px;
    }

    .left {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1;

        .input-custom-generic {
            font-size: 18px;
            color: black;
            padding: 6px 8px 6px 12px;
            height: 46px;
            border: 1px solid #707070;

            &:hover {
                cursor: pointer;
            }

            &:focus {
                outline: none;
                box-shadow: 0px 0px 1px rgba(0,100,255,1),
                0px 0px 2px rgba(0,100,255,1),
                0px 0px 3px rgba(0,100,255,1)
            }
        }

        .input-left-bordered {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }

        .input-right-bordered {
            background-color: white;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
        }

        .input-date-width {
            max-width: 100%;

            @media screen and (max-width: 767px){
                width: 100%!important;
            }
        }

        .input-normal-bordered {
            border-radius: 4px;
        }

        .by-name {
            width: 125px;
            margin-bottom: 6px;
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0;
            }
        }

        .by-id {
            width: 100px;
            margin-bottom: 6px;
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0;
            }
        }

        .by-date {
            width: 200px;
            margin-bottom: 6px;
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0;
            }
        }

        .by-channel {
            width: 190px;
            margin-bottom: 6px;
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0;
            }
        }

        .by-search {
            width: 160px;
            margin-bottom: 6px;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .right {
        .by-export {
            width: 160px;
            margin-bottom: 6px;
            margin-left: 100px;

            @media screen and (max-width: 1400px) {
                margin-left: 6px;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0;
            }
        }

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}
