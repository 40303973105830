@import 'variable.scss';

.btn {
  border-radius: 5px;
  padding: 10px 25px;
  margin-bottom: 20px;
  margin-right: 15px;
  //transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  text-transform: none!important;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: #4ce1b6;
    //transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
  }

  &:hover, &:focus, &:active, &:active:focus {
    outline: none;
    box-shadow: none !important;

    &:before {
      height: 500%;
      width: 225%;
    }
  }

  &:focus, &:active, &:active:focus {
    &:before {
      transition: all 0s;
    }
  }

  &.square {
    border-radius: 0;
  }

  &.fifty-percent-rounded {
    border-radius: 50px !important;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.login-rounded {
    border-radius: 8px !important;
  }

  &.contct-us-padding {
    padding: 24px 100px !important;
  }

  &.contct-us-rounded {
    border-radius: 50px !important;
  }

  &.icon {
    padding: 10px 15px;

    &:hover {

      p {
        color: #646777;
      }

      svg {
        fill: #646777;
      }
    }

    &.icon--right {

      svg {
        margin: 2px 0 0 5px;
      }
    }
  }

  &.btn-sm {
    padding: 5px 25px;
    font-size: 14px;
  }

  &.btn-md {
    padding: 13.5px 25px;
    font-size: 18px;
  }

  &.btn-lg {
    padding: 24px 25px;
    font-size: 24px;
    // font-size: 14px;
  }

  &.btn-primary {
    color: white;
    background-color: $color-green;
    border-color: $color-green-hover;
    box-shadow: 0px 5px 10px #82D4C566;

    &:before {
      background-color: $color-green-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-green-hover;
    }

    &:disabled, btn-primary[disabled]  {
      //color: white!important;
      //cursor: not-allowed;
      //pointer-events: all !important;
    }
  }

  &.btn-secondary {
    color: white;
    background-color: $color-green-hover;
    border-color: $color-green-hover;
    box-shadow: 0px 5px 10px #82D4C566;

    &:before {
      background-color: $color-green;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-green;
    }

    &:disabled, btn-secondary[disabled]  {
      //color: white!important;
      //cursor: not-allowed;
      //pointer-events: all !important;
    }
  }

  &.btn-secondary-red {
    color: white;
    background-color: $color-red;
    border-color: $color-red-hover;
    box-shadow: 0px 5px 10px #82D4C566!important;

    &:before {
      background-color: $color-red-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-red-hover;
    }

    &:disabled, btn-secondary-red[disabled]  {
      //color: white!important;
      //cursor: not-allowed!important;
      //pointer-events: all !important;
      //pointer-events: none !important;
    }
  }

  &.btn-outline-primary {
    color: #000000;
    background-color: white;
    border: 1px solid #707070;

    &:before {
      background-color: $color-green;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-green-hover;
      color: white;
    }
  }
}
