.file-upload-dropzone-title {
    text-align: center;
    padding-bottom: 4px;
    font-size: 25px;
    color: #858589;
}

.file-margin {
    margin-top: 50px;
}

.file-sign-re-margin {
    margin-top: 24px;
}

.MuiDropzoneArea-root {
    // border: 1px dashed #707070;
    border: none!important;
}

.MuiDropzoneArea-root:before {
    content: "";
    position: absolute;
    border: 10px dashed #707070;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
}

.MuiDropzoneArea-icon {
    color: #707070!important;
    width: 68px!important;
    height: 64px!important;
}

.MuiDropzoneArea-textContainer{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    
    p {
        color: #B2B2B2;
    }
}