@import '../../scss/variable.scss';

.direct-signature-main {
    padding-top: 80px;

    .title {
        font-size: 25px;
        color: $color-qa-title;
    }

    .file-container {
        margin-bottom: 24px;
    }

    .recieve-email-checkbox-container {
        display: flex;

        .rec-email-text {
            font-size: 20px;
            color: $color-qa-title;
            margin-left: 13px;
        }
    }

    .form-main-container {
        padding-bottom: 40px;
    }

    .edit-account-form {
        padding-top: 10px;

        .edit-acc-label {
            padding: 10px;
            color: #484848; 
            font-size: 20px;
        }

        .edit-acc-text-field {
            width: 100%;
            color: #858589;
            font-size: 20px;
            padding: 11px 0 10px 21px;
            border: 1px solid #707070;
        }

        .edit-margin-btm {
            margin-bottom: 5px;
        }
    }

    .title-padding-top {
        padding-top: 100px;
    }

    .sign-btns-container {
        display: flex;
        justify-content: center;
        margin-top: 70px;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }

        .signature-btns {
            height: 62px;
            width: 251px; 
            
        }

        .signature-margin-right {
            margin-right: 14px;

            @media screen and (max-width: 600px) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
}