@import '../../scss/variable.scss';

.header {
    top: 0;
    right: 0;
    position: fixed;
    display: flex;
    z-index: $header-z-index;
    width: 100%; //$header-width
    max-width: 100%; //$header-width
    height: $header-height;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #0000000D;
    opacity: 1;

    .header-first-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: inherit;
        width: 100%;;
    }

    .header-second-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .menu-icon-header {
        display: none;

        &:hover {
            font-size: 1.6rem;
        }
    }

    .language-name_container {
        padding-right: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .headerLogo {
        padding-left: 10rem;
    }

    .sidebarClose {
        display: none;
    }


}

@media screen and (max-width: 768px) {
    .header {
        width: 100%;
        max-width: 100%;

        .header-first-container {
           width: 100%;
            height: inherit;
        }

        .header-second-container {
            justify-content: space-between;
        }

        .menu-icon-header {
            // font-size: 4rem;
            height: 60px;
            width: 60px;
            color: $color-green;
            display: flex;
            padding-left: 1rem;
            cursor: pointer;
        }

        .language-name_container {
            padding-right: 1.5rem;
        }

        .headerLogo {
            display: none;
        }
    }
}

.sidebarOpen {
    z-index: $toggle-sidebar-z-index;
    display: block;
    width: $toggle-sidebar-width;
    height: 100%;
    padding: 24px 0 0 27px;
    position: fixed;
    cursor: pointer;
    color: white;
    background-color: $color-green;

    .close-icon_container {
        padding-bottom: 100px;
    }

    .close-icon {
        color: white;
        margin-bottom: 20px;
        font-size: 30px;
    }

    .items-container {
        display: flex;
        flex-direction: column;
    }

    .sidebar-item {
        display: flex;

        // &:hover {
        //     border-bottom: 1px solid white;
        // }
    }

    .sidebar-icons {
        font-size: 58px;

        &:hover {
            cursor: pointer;
        }
    }

    .icon-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 35px;
        font-size: 20px;
    }
}

@media screen and (max-width: 600px) {
    .sidebarOpen {
        width: 100vw;
    }
}



.sidebarClose {
    display: none;
}
