@import "../../../scss//variable.scss";


.about {
    margin-top: 144px;
    margin-bottom: 100px;
    
    .left-title {
        padding-top: 15px;
        font-size: 25px;
        color: $color-home-desc-dark;
    }
    
    .right-title {
        color: $color-about-us-title;
        font-size: 60px;
        padding-bottom: 50px;

        @media screen and (max-width: 500px) {
            font-size: 50px;
            padding-bottom: 50px;
        }
    }
    
    .right-line {
        width: 140px;
        border-bottom: 6px solid $color-contct-us-input-field;
    }

    .right-description {
        padding-top: 71.5px;
        font-size: 25px;
        color: $color-home-desc-dark;
    }

    .about-contact-us-btn {
        margin-top: 100px;
        margin-bottom: 200px;

        @media screen and (max-width: 500px) {
            button {
                width: 100%;
            }
        }
    }

    .max-height-contact-us {

        @media screen and (max-width: 767px) {
            max-height: 65px;
        }
    }
}


