.grid--stepper-wrapper {
    padding: 0 20px;

    @media screen and (max-width: 550px){
        padding: 0 0;
    }

    .grid-stepper {
        display: grid;
        grid-template-rows: 26px;
        // grid-template-rows: 26px 37px;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 450px) {
            padding: 13px 10px;
        }

        .circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;

            &:hover {
                cursor: pointer;
            }
        }

        .circle-inactive {
            border: 1px solid #707070;
        }

        .circle-active {
            border: 1px solid #7B87F6;
            box-shadow: 0px 3px 6px #00000029;
        }

        .line {
            width: 1fr;
            border-bottom:1px solid #707070;;
        }
    }

    .three-steps {
        padding: 13px 34px;
        grid-template-columns: 26px 1fr 26px 1fr 26px;
    }

    .two-steps {
        padding: 13px 40px;
        grid-template-columns: 26px 1fr 26px;
    }

    .stepper-text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .circle-text {
            grid-row: auto;
            font-size: 20px;

            &:hover {
                cursor: pointer;
            }

            @media screen and (max-width: 450px) {
                font-size: 14px;
            }
        }

        .circle-inactive-text {
            color: #707070;
        }

        .circle-active-text {
            color:#7B87F6;
        }

        .circle-active-error-text {
            color: #0A49BE;
        }
    }

}

    

