
@import "../../scss/variable.scss";

.aboutUs {
    margin-bottom: 100px;

    @media screen and (max-width: 600px) {
        margin-bottom: 0;
    }

    .about-us_title {
        font-size: 25px;
        color: $color-qa-title;
    }

    .about-us_detail {
        color: $color-qa-title;
        font-size: 15px;
        margin-top: 14px;
        margin-bottom: 25px;
        white-space: pre-line;
    }
}