.tooltip-custom-icon {
  //padding-top: 4px;

  &:hover {
    color: #ffc423!important;
    cursor: pointer;
  }
}

.custom-toolTip {
  position: absolute;
  width: 300px;
  max-width: 300px;
  z-index: 100;
  background-color: rgba(0,0,0,0.5);
  color: white;
  padding: 6px;
  top: -18px;
  //left: 66px;
  border-radius: 4px;
  max-height: 37px;
  //display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-toolTip > span {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.show-custom-toolTip {
  display: flex;
}

.hide-custom-toolTip {
  display: none;
}
