.main-menu-container {
    right: 0;
    top: 40px;
    position: absolute;
    z-index: 50;
    display: flex;
    flex-direction: column;
    color: #707070;

    .arrow-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 20px;
    }

    .arrow-up-black {
        position: relative;
        width: 0; 
        height: 0; 
        border-left: 11px solid transparent;
        border-right: 12px solid transparent;
        
        border-bottom: 11px solid #4C4C6D;
    }

    .arrow-up-orange {
        position: relative;
        left: -11px;
        top: 2px;
        width: 0; 
        height: 0; 
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        
        border-bottom: 11px solid white;
    }

    .generic-menu {
        width: 194px;
        border: 1px solid #4C4C6D;
        border-radius: 10px;
        box-shadow: 0px 5px 10px #0000008F;
        background-color: #ffffff;

    }

    .language-menu {
        height: 121px;
        max-height: 121px;

        .items {
            padding: 20px;
            border-bottom: 1px solid #4C4C6D;
        }

        .items:nth-last-child(1) {
            border-bottom: 0px;
        }
    }

    .profile-menu {
        padding-top: 15px;
        height: auto;
        // height: 217px;
        // max-height: 217px;

        .items {
            padding: 15px;
            border-bottom: 1px solid #4C4C6D;
        }

        .items:nth-last-child(1) {
            border-bottom: 0px;
        }
    }

    .menu-display-none {
        display: none;
    }

}