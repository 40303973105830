.custom-check-box {
    width: 27px;
    height: 27px;
    border: 1px solid #707070;

    &:hover {
        cursor: pointer;
    }
}

.custom-check-box-tick_image {
    width: 27px;
    height: 27px;

    &:hover {
        cursor: pointer;
    }
}