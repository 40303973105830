.job-detail-content {

  .job-detail-title {
    font-size: 25px;
    margin-bottom: 23px;
    color: #484848;
  }

  .job-detail-data {
    font-size: 18px;
    padding: 10px 0;
    display: flex;

    @media screen and (max-width: 650px) {
      flex-direction: column;
      //flex-direction: row;
      //justify-content: space-between;
    }

    .job-detail-root-key {

      @media screen and (max-width: 650px) {
        width: 125px!important;
      }
    }

    .job-detail-key {
      width: 30%;

      @media screen and (max-width: 650px) {
        //width: auto;
        width: 100%;
      }
    }

    .job-detail-value {
      width: 70%;

      @media screen and (max-width: 650px) {
        //width: auto;
        width: 100%;
      }
    }
  }

  .job-detail-hash {
    overflow-wrap: anywhere;
  }
}
