.ant-table {
    border: 1px solid #707070;
    overflow: auto;
}

.ant-table-cell {
    text-align: center;
}

.ant-table-thead > tr > th {
    font-size: 18px;
    // color: #4C4C6D;
    color: white;
    text-align: center;
    // background-color: #FFE194!important;
    background-color: #40BAD5!important;
    border-left: 3px solid white;
    border-top: 3px solid white;
}

.ant-table-thead > tr > th.ant-table-cell:not(:first-child) {
    border-left: none;
}

.ant-table-thead > tr > th.ant-table-cell:last-child {
    // border-left: none;
    border-right: 3px solid white;
}

.ant-table-tbody > tr > td {
    padding: 10px 16px;
    font-size: 15px;
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
}

.ant-table-cell:last-child{
    border-right: none;
}

.ant-table-tbody > tr:last-child .ant-table-cell {
    border-bottom: none;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 0;
}

